import axios from 'axios'
import { message } from 'antd'

let baseURL = ''

if (process.env.NODE_ENV === "development") {
  baseURL = "/api"
} else if (process.env.CMLINT_ENV === "test") {
  baseURL = "https://twriting.haiduxiaoshuo.com"
} else {
  baseURL = "https://writing.haiduxiaoshuo.com"
}

const http = axios.create({
  baseURL: baseURL
})

http.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token')
    if (token) config.headers.Authorization = token
    return config
  }
)

http.interceptors.response.use(
  response => {
    const { data } = response
    // 未登录处理 去登录页面
    if (data?.errCode === 4001) {
      localStorage.removeItem('token')
      // 这么写不太好看,后面补上 https://github.com/ReactTraining/history/blob/master/docs/navigation.md
      window.location.href = '/' 
      throw data.errMsg
    }
    // 未设置密码 去设置密码
    if (data?.errCode === 4003) {
      window.location.href = '/setPassword'
      throw data.errMsg
    }
    if (data?.errCode === 0) {
      return data
    } else {
      // ElMessage.error(data?.errMsg || "网络错误请重试")
      const errMsg = data.errMsg || '网络错误请重试'
      message.error(errMsg)
      throw errMsg
    }
  }
)

export default http