
import { BrowserRouter } from 'react-router-dom'
import '@assets/style/antd.css'
import { renderRoutes } from 'react-router-config';
import routes from './router/index'
import Store from '@store'
import { Suspense } from 'react'

function App() {
  return (
    <div className="App">
      <Suspense fallback={<></>}>
        <BrowserRouter>
          <Store>
            {renderRoutes(routes)}
          </Store>
        </BrowserRouter>
      </Suspense>
    </div>
  )
}

export default App;
