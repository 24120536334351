import http from './config'

// 密码登录
export function passwordLogin(data) {
  return http({
    url: '/user/passwordLogin',
    method: 'post',
    data
  })
}

// 发送验证码
export function sendVCode(data) {
  return http({
    url: '/user/sendVerificationCode',
    method: 'post',
    data
  })
}

// 验证码登录
export function vCodeLogin(data) {
  return http({
    url: '/user/verificationCodeLogin',
    method: 'post',
    data
  })
}

//标记已读
export function isNewPrompt(params) {
  return http({
    url: '/message/newPrompt',
    method: 'get',
    params
  })
}

//获取消息列表
export function getTipsList(params) {
  return http({
    url: '/message/getTipsList',
    method: 'get',
    params
  })
}

//全部已读
export function allReadInfo(data) {
  return http({
    url: '/message/allRead',
    method: 'post',
    data
  })
}


//标记已读
export function itemMarkRead(data) {
  return http({
    url: 'message/markRead',
    method: 'post',
    data
  })
}


// 申请撤回 一个接口能撤回很多操作, 看 applyType 类型
export function revocationApply(data) {
  return http({
    url: 'user/revocationApply',
    method: 'post',
    data
  })
}

// 设置密码
export function postSetPassword(data) {
  return http({
    url: 'user/setPassword',
    method: 'post',
    data
  })
}

// 忘记密码
export function postForgetPassword(data) {
  return http({
    url: 'user/forgetPassword',
    method: 'post',
    data
  })
}


// 找回设置密码
export function postRetrievePassword(data) {
  return http({
    url: 'user/retrievePassword',
    method: 'post',
    data
  })
}



// 作者申请
export function authorApplyInfo(data) {
  return http({
    url: 'user/authorApply',
    method: 'post',
    data
  })
}

// 作者详情
export function getAuthorInfo(params) {
  return http({
    url: 'user/getAuthorInfo',
    method: 'get',
    params
  })
}

// 用户信息唯一校验 validationData 验证数据, 
// 验证类型: 笔名 pseudonym 验证码 idnumber  邮箱 email 微信 wechat 扣扣 qq  银行卡 bankCard
export function uniqueCheck(params) {
  return http({
    url: 'user/authorInfoUniqueCheck',
    method: 'get',
    params
  })
}

// 笔名唯一校验
export function everyDay(params) {
  return http({
    url: 'user/everyDayChapterWords',
    method: 'get',
    params
  })
}

// 作者请假
export function creationLeave(data) {
  return http({
    url: 'user/bookCreationLeave',
    method: 'post',
    data
  })
}

// 笔名唯一校验
export function searchApplyData(params) {
  return http({
    url: 'user/searchApplyData',
    method: 'get',
    params
  })
}

// 微信登录-获取code
export function getWechatCodeUrl(params) {
  return http({
    url: 'user/getWechatCodeUrl',
    method: 'get',
    params
  })
}

// 微信登录-授权登陆
export function wechatLogin(params) {
  return http({
    url: 'user/wechatLogin',
    method: 'get',
    params
  })
}

// 微信解绑
export function removeThirdBind(data) {
  return http({
    url: 'user/removeThirdBind',
    method: 'post',
    data
  })
}

// 微信绑定
export function wechatBindAuthor(data) {
  return http({
    url: 'user/wechatBindAuthor',
    method: 'post',
    data
  })
}

// qq登录-获取code
export function getQqCodeUrl(params) {
  return http({
    url: 'user/getQqCodeUrl',
    method: 'get',
    params
  })
}

// qq登录
export function qqLogin(params) {
  return http({
    url: 'user/qqLogin',
    method: 'get',
    params
  })
}

// qq绑定
export function qqBindAuthor(data) {
  return http({
    url: 'user/qqBindAuthor',
    method: 'post',
    data
  })
}

// 福利页面责编分组列表
export function getEditorList(params) {
  return http({
    url: 'user/getEditorList',
    method: 'get',
    params
  })
}

// 作者建议反馈
export function saveFeedback(data) {
  return http({
    url: 'user/saveFeedback',
    method: 'post',
    data
  })
}



