import { useState, createContext } from 'react';
import { getAuthorInfo ,isNewPrompt} from '@api/user';
import { useHistory } from 'react-router-dom'
import '@assets/style/reset.css'  // 写在这里是因为有层级问题,后面再改
import '@assets/style/class.css'
import { StatusUser } from '@utils/userStatus'

export const Context = createContext({})

function Store(props) {
  const { children } = props
  const history = useHistory()
  const [user, setUser] = useState({})
  const [message, setMessage] = useState({newPromptCount: 0})
  return <Context.Provider value={{
    user,
    getUserInfo() {
      getAuthorInfo().then(({ data = {} }) => {
        const statusText = StatusUser(data)
        if (['未签约', '签约驳回', '修改驳回'].includes(statusText)) {
          setUser({
            ...data,
            lock: true
          })
          if (statusText === '未签约') {
            history.push('/home/applyAuthor')
          } else {
            history.push('/home/applyAuthor?editStatus=1')
          }
        } else {
          setUser(data)
        }
      })
    },
    clearUserInfo() {
      setUser({})
    },
    message,
    getMyMessage(){
      isNewPrompt({}).then(({data})=>{
        setMessage({
          ...data
        })
      }) 
    }
  }}>
    {children}
  </Context.Provider>
}
export default Store