import { lazy } from 'react'
// import RootPage from '@cp/rootPage'
const Login = lazy(() => import('../pages/login'))
const Welfare = lazy(() => import('../pages/welfare'))
const Home = lazy(() => import('../components/home'))
const BookInfo = lazy(() => import('../components/bookInfo'))
const BookList = lazy(() => import('../pages/bookList'))
const CreateBook = lazy(() => import('../pages/createBook'))
const BookSet = lazy(() => import('../pages/bookSet'))
const BookContent = lazy(() => import('../pages/bookContent'))
const BookDrafts = lazy(() => import('../pages/bookDrafts'))
const Payment = lazy(() => import('../pages/payment'))
const BookFee = lazy(() => import('../pages/bookFee'))
const BookFeeDetail = lazy(() => import('../pages/bookFeeDetail'))
const UserInfo = lazy(() => import('../pages/userInfo'))
const HomeWelfare = lazy(() => import('../pages/homeWelfare'))
const MessageNotice = lazy(() => import('../pages/messageNotice'))
const Publish = lazy(() => import('../pages/publish'))
const SetPassword = lazy(() => import('../pages/setPassword'))
const applyAuthor = lazy(() => import('../pages/applyAuthor'))
const ResetPassword = lazy(() => import('../pages/resetPassword'))
const ShieldList = lazy(() => import('../pages/shieldList'))
const WechatLogin = lazy(() => import('../pages/wechatLogin'))
const PhoneBind = lazy(() => import('../pages/phoneBind'))
const QqLogin = lazy(() => import('../pages/qqLogin'))
const Unbind = lazy(() => import('../pages/unbind'))
const BindAuthor = lazy(() => import('../pages/bindAuthor'))
const Down = lazy(() => import('../pages/down'))
const PhoneWelfare = lazy(() => import('../pages/phoneWelfare'))
const ShareBook = lazy(() => import('../pages/shareBook'))
const Introduce = lazy(() => import('../pages/introduce'))
const SuggestFeedback = lazy(() => import('../pages/suggestFeedback'))
/* 外挂的移动端，不要问我为什么，我只能说厂长是我表哥 */
const MobileList = lazy(() => import('../pages/phoneView/list'))
const Catalogue = lazy(() => import('../pages/phoneView/catalogue'))
const Article = lazy(() => import('../pages/phoneView/article'))
// 新的app下载地址 就是个图片
const AppDown = lazy(() => import('../pages/app-down'))
const routes = [
  {
    path: '/home',
    component: Home,
    children: [
      {
        path: '/home/booklist',
        component: BookList
      },
      {
        path: '/home/createbook',
        component: CreateBook
      },
      {
        path: '/home/payment',
        component: Payment
      },
      {
        path: '/home/fee',
        component: BookFee
      },
      {
        path: '/home/feeDetail/:bookId',
        component: BookFeeDetail
      },
      {
        path: '/home/userInfo',
        component: UserInfo
      },
      {
        path: '/home/suggestFeedback',
        component: SuggestFeedback
      },
      {
        path: '/home/messageNotice',
        component: MessageNotice
      },
      // 为什么有2个福利页面呢? 是因为登录和非登录的作家福利的头部是不一样的
      {
        path: '/home/welfare',
        component: HomeWelfare,
        exact: true
      },
      {
        path: '/home/bookInfo/:bookId',
        component: BookInfo,
        children: [
          {
            path: '/home/bookInfo/:bookId/bookSet',
            component: BookSet
          },
          {
            path: '/home/bookInfo/:bookId/bookContent',
            component: BookContent
          },
          {
            path: '/home/bookInfo/:bookId/bookDrafts',
            component: BookDrafts
          },
          {
            path: '/home/bookInfo/:bookId/shieldList',
            component: ShieldList
          }
        ]
      },
      {
        path: '/home/applyAuthor',
        component: applyAuthor,
        exact: true
      }
    ]
  },
  {
    path: '/',
    component: Login,
    exact: true
  },
  {
    path: '/welfare',
    component: Welfare,
    exact: true
  },
  {
    path: '/publish/:bookId',
    component: Publish,
    exact: true
  },
  {
    path: '/setPassword',
    component: SetPassword,
    exact: true
  },
  {
    path: '/resetPassword',
    component: ResetPassword,
    exact: true
  },
  {
    path: '/wechatLogin',
    component: WechatLogin,
    exact: true
  },
  {
    path: '/phoneBind',
    component: PhoneBind,
    exact: true
  },
  {
    path: '/qqLogin',
    component: QqLogin,
    exact: true
  },
  {
    path: '/unbind/:type', // wechat 和 qq
    component: Unbind,
    exact: true
  },
  {
    path: '/bindAuthor/:type', // wechat 和 qq
    component: BindAuthor,
    exact: true
  },
  {
    path: '/down',
    component: Down,
    exact: true
  },
  {
    path: '/app-down',
    component: AppDown,
    exact: true
  },
  {
    path: '/phoneWelfare',
    component: PhoneWelfare,
    exact: true
  },
  {
    path: '/shareBook',
    component: ShareBook,
    exact: true
  },
  {
    path: '/introduce',
    component: Introduce,
    exact: true
  },
  // 调海读文学接口的页面
  {
    path: '/mobileList',
    component: MobileList
  },
  {
    path: '/catalogue',
    component: Catalogue
  },
  {
    path: '/article',
    component: Article
  },
  // 调用快应用接口的页面，他们都是一个页面就是接口不一样
  {
    path: '/appMobileList',
    component: MobileList
  },
  {
    path: '/appCatalogue',
    component: Catalogue
  },
  {
    path: '/appArticle',
    component: Article
  }
]

export default routes
