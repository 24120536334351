/**
 * @description 用户状态只有一种 那就是是否作者申请签约过审
 */



/**
 * @description 返回此 作者 是否签约过初审
 */
const statusMap = {
  "10-0": "未签约",
  "10-1": "签约待审核",
  "10-2": "签约审核中",
  "10-3": "签约驳回",
  "20-0": "已签约",
  "20-1": "修改待审核",
  "20-2": "修改审核中",
  "20-3": "修改驳回",
}
export function StatusUser({status, statusEdition}) {
  return statusMap[status + '-' + statusEdition]
}


const contractStatusButtonTextMap = {
  "10-3": "签约驳回"
}

export function StatusContractButtonText({status, statusEdition}) {
  return contractStatusButtonTextMap[status + "-" + statusEdition]
}